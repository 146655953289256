import '@angular/localize/init';
import 'classlist.js';

if (!('animate' in document.documentElement)) {
  const script = document.createElement('script');
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/web-animations/2.3.2/web-animations.min.js';
  document.head.appendChild(script);
}
import 'zone.js';

import * as process from 'process';
window['process'] = process;

(window as any).global = window;
